import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function formatDateTime(updatedAt) {
  if (!updatedAt) {
    return ''
  }

  let updatedDate

  try {
    updatedDate = new Date(Date.parse(updatedAt))
    if (isNaN(updatedDate.getTime())) {
      throw new Error('Invalid date')
    }
  } catch (error) {
    return updatedAt // or handle the error in another appropriate way
  }

  const currentDate = new Date()
  const timeDifference = currentDate.getTime() - updatedDate.getTime()
  const millisecondsInAnHour = 1000 * 60 * 60
  const millisecondsInADay = millisecondsInAnHour * 24
  const millisecondsInAMonth = millisecondsInADay * 30
  const millisecondsInAYear = millisecondsInADay * 365

  // Handle future dates
  if (timeDifference < 0) {
    const absDifference = Math.abs(timeDifference)

    if (absDifference < millisecondsInAnHour) {
      const minutes = Math.floor(absDifference / (1000 * 60))
      return `in ${minutes} minute${minutes !== 1 ? 's' : ''}`
    } else if (absDifference < millisecondsInADay) {
      const hours = Math.floor(absDifference / millisecondsInAnHour)
      return `in ${hours} hour${hours !== 1 ? 's' : ''}`
    } else if (absDifference < millisecondsInAMonth) {
      const days = Math.floor(absDifference / millisecondsInADay)
      return `in ${days} day${days !== 1 ? 's' : ''}`
    } else if (absDifference < millisecondsInAYear) {
      const months = Math.floor(absDifference / millisecondsInAMonth)
      return `in ${months} month${months !== 1 ? 's' : ''}`
    } else {
      const years = Math.floor(absDifference / millisecondsInAYear)
      return `in ${years} year${years !== 1 ? 's' : ''}`
    }
  }

  // Handle past dates
  if (timeDifference < millisecondsInAnHour) {
    const minutes = Math.floor(timeDifference / (1000 * 60))
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`
  } else if (timeDifference < millisecondsInADay) {
    const hours = Math.floor(timeDifference / millisecondsInAnHour)
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`
  } else if (timeDifference < millisecondsInAMonth) {
    const days = Math.floor(timeDifference / millisecondsInADay)
    return `${days} day${days !== 1 ? 's' : ''} ago`
  } else if (timeDifference < millisecondsInAYear) {
    const months = Math.floor(timeDifference / millisecondsInAMonth)
    return `${months} month${months !== 1 ? 's' : ''} ago`
  } else {
    const years = Math.floor(timeDifference / millisecondsInAYear)
    return `${years} year${years !== 1 ? 's' : ''} ago`
  }
}

export function sortChapters(chapters) {
  return chapters.sort((a, b) => {
    const numA = parseFloat(a.title.match(/\d+(\.\d+)?/)[0])
    const numB = parseFloat(b.title.match(/\d+(\.\d+)?/)[0])
    return numB - numA
  })
}

export function getChapterControls(chapters) {
  const firstChapter = chapters?.length > 1 ? chapters[chapters.length - 1] : null
  const lastChapter = chapters?.length > 0 ? chapters[0] : null

  return {
    firstChapter,
    lastChapter,
  }
}

export function getPaginationMetadata(currentPage, totalPages) {
  const other = []
  const _currentPage = Number(currentPage)

  const prevPage = _currentPage > 1 ? _currentPage - 1 : null
  const nextPage = _currentPage < totalPages ? _currentPage + 1 : null

  if (prevPage) {
    other.push({
      rel: 'prev',
      url: `/?page=${prevPage}`,
    })
  }

  if (nextPage) {
    other.push({
      rel: 'next',
      url: `/?page=${nextPage}`,
    })
  }

  return {
    icons: other,
  }
}

export function getImageUrl(data, folder = 'storytelling') {
  let imageName = data?.cover_image || data?.image_url;

  if (!imageName) {
    return '#'
  }

  if (imageName.startsWith('http')) {
    const encodedUrl = btoa(imageName)
    return `/api/cdn-image?url=${encodedUrl}`
    //return `/api/cdn-image?url=${imageName}`
  }
  
  imageName = imageName.replace('comics-en/', 'mangakakalotx/');

  return `https://cdn-en2.mancloud.info${imageName}`
}

export function getLimitedWords(text, wordLimit = 100, charLimit = 100) {
  const words = text.split(/\s+/)

  let result = ''
  let wordCount = 0
  let charCount = 0

  for (const word of words) {
    if (wordCount >= wordLimit || charCount + word.length > charLimit - 3) {
      result += '...'
      break
    }

    if (result) {
      result += ' '
      charCount++
    }

    result += word
    wordCount++
    charCount += word.length
  }

  // Nếu văn bản gốc dài hơn kết quả, thêm dấu "..."
  if (text.length > result.length && !result.endsWith('...')) {
    result = result.slice(0, charLimit - 3) + '...'
  }

  return result.trim()
}

export function flattenAttributes(data) {
  // Check if data is a plain object; return as is if not
  if (
    typeof data !== 'object' ||
    data === null ||
    data instanceof Date ||
    typeof data === 'function'
  ) {
    return data
  }

  // If data is an array, apply flattenAttributes to each element and return as array
  if (Array.isArray(data)) {
    return data.map((item) => flattenAttributes(item))
  }

  // Initialize an object with an index signature for the flattened structure
  let flattened = {}

  // Iterate over each key in the object
  for (let key in data) {
    // Skip inherited properties from the prototype chain
    if (!data.hasOwnProperty(key)) continue

    // If the key is 'attributes' or 'data', and its value is an object, merge their contents
    if (
      (key === 'attributes' || key === 'data') &&
      typeof data[key] === 'object' &&
      !Array.isArray(data[key])
    ) {
      Object.assign(flattened, flattenAttributes(data[key]))
    } else {
      // For other keys, copy the value, applying flattenAttributes if it's an object
      flattened[key] = flattenAttributes(data[key])
    }
  }

  return flattened
}

export function getStrapiURL() {
  return process.env.NEXT_PUBLIC_STRAPI_URL ?? 'http://localhost:1338'
}

export function getStrapiMedia(url) {
  if (url == null) return null
  if (url.startsWith('data:')) return url
  if (url.startsWith('http') || url.startsWith('//')) return url
  return `${getStrapiURL()}${url}`
}
